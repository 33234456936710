.scorestyle{
    font-size: 20px;
    font-weight: bold;
}

.custom-container {
    // max-width: 1000px; /* Adjust this value to your desired width */
    margin: 0 auto; /* Center the container horizontally */
  }
  .moredetailstop{
    margin-top: 70px;
  }

  .remove-position{
    bottom: 0px !important;
    left:0px !important;
  }
