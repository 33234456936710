.modal-body {
  padding: 20px;

  margin: 0 auto;
}

.modalcard {
  text-align: center;
}

.form-label {
  font-weight: bold;
}

.top-height {
  margin-top: 0px;
}

.form-control {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #2b4257;
  color: white;
}

.mb-3 {
  margin-bottom: 15px;
}

.edit-button {
  background-color: orange;
}

.ratemycustomerclose {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 45px;
  font-size: 20px;
}
.close-icon {
  font-size: 20px;
}
.loader {
  margin-top: 50px;
  color: aqua;
}

.total_customer {
  border-radius: 6px;
  padding: 12px;
  color: #fff;
  background-color: #2b4257;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  width: "200px";
  margin-right: 6px;
  margin-top: 4px;
}
.total_partner {
  border-radius: 6px;
  padding: 12px;
  color: #fff;
  background-color: #2b4257;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  width: "200px";
  margin-top: 4px;
}
.number_of_disputes {
  border-radius: 6px;
  padding: 12px;
  color: #fff;
  background-color: #2b4257;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  width: "200px";
  margin-top: 4px;
}
