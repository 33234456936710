
.heading {
  padding-left: 300px;
  color: rgb(8, 8, 136);
}

.totalcustomerscontainer {
  margin-left: 12px;
  margin-Top: 22px;
  font-Size: 10px;

}

.marginTopNegative10 {
  margin-top: -10px;
}
.mobileback {
  display: inline-block;
  padding: 12px 15px;
  color: #012970;
  font-size: 10px;
  font-weight: bold;
  text-align: right;
  margin-right: 10px;
}
.back{
  display: inline-block;
  padding: 5px 12px;
  color: #012970;
  font-size: 19px;
  font-weight: bold;
  text-align: right;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #012970;
  border-radius: 9px;
}
.reachability{
  margin-top: -50px;
}
.payment
{
  padding-top: 110px;
  padding-right: 150px;
  padding-bottom: 125px;
  width: 140%;
}
.text
{
   padding-top: 45px;
}
.back a {
  display: flex;
  align-items: center;
  color: rgb(68, 72, 133);
  text-decoration: none;
}

.back a svg {
  margin-right: 5px;
}

.rating {
  position: absolute;
  width: 380px;
  height: 400px;
  left: 232px;
  top: 265px;
  margin-left: 135px;
  padding-top: 10px;
  padding-left: 15px;
  background: #d1cfe2;
  backdrop-filter: blur( 20px );
  -webkit-backdrop-filter: blur( 20px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );

}
.partnerdetailsrating{

  height: auto;
  padding: 5px;
  width: 100%;
  margin: 10px auto 0px;
  backdrop-filter: blur( 20px );
  -webkit-backdrop-filter: blur( 20px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  
}

.rating1{
  height: auto;
  padding: 5px;
  max-width: 400px;
  margin: 0px auto 20px;
  background: #d1cfe2;
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 20px );
  -webkit-backdrop-filter: blur( 20px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  
}


.com {
  text-align: center;
  color: #333;

}

.number {
  position: absolute;
  width: 380px;
  height: 140px;
  left: 132px;
  top: 180px;
  margin-left: 644px;
  background: rgba( 240, 241, 244, 0.3 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 20px );
  -webkit-backdrop-filter: blur( 20px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.review {
  position: absolute;
  width: 590px;
  height: 400px;
  left: 570px;
  top: 170px;
}

div.css-1oqqzyl-MuiContainer-root {
  position: absolute;
  width: 780px;
  height: 490px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.644);
  border-radius: 10px;
}

div.css-1d3jhju-MuiAvatar-root {
  margin: 0px auto;
}

.star {
  text-align: center;
}

.riv {
  font-weight: bold;
  font-size: 18px;
  color: #fff;
}
.ratimg{
  position: absolute;
  width: 350px;
  height: 120px;
  left: 132px;
  top: 330px;
}
.rectangle {
  width: 100%;
  margin: 10px auto;
  padding: 10px;
  border-radius: 30px;
  text-align: center;
  margin-left: 1px;
  height: fit-content;
  
    
}

@media (max-width: 1200px) { /* Adjust 768px to your medium screen breakpoint */
  .rectangle {
    height: auto;
  }

}
@media (max-width: 480px) {
  .payment{
    padding-top: 40px;
    width: 120%;
    height: 110%;
    padding-left: 80px;
  }
  .text{
    font-size: larger;
    padding-top: 80px;
  }
}

.rectangle .MuiGrid-root {
  margin-left: 0px;
  padding-left: 0px !important;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.dashboard{
  width: 260px;
  height: 60px;
  border-radius: 8px;
  margin: 16px;
  padding: 16px;
  color: #000000;
  background: #98A9D7;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
  margin-top: 10px;
  
}
.cardone{
  height: 60px;
  border-radius: 8px;
  margin: 5px 3px;
  padding: 16px;
  color: #fff;
  background: #2b4257;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  
}

.card2{
  height: 60px;
  border-radius: 8px;
  margin: 5px 3px;
  padding: 16px;
  color: #fff; 
  background: #2b4257;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  
}
.card3 {
  height: 60px;
  border-radius: 8px;
  margin: 5px 3px;
  padding: 16px;
  color: #fff; 
background: #2b4257;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

}
.customer-name{
  color: #2b4257;
  font-family:'Ruwudu';
font-weight: 700;
}


/* formik-form.css */

.formik-form {
  max-width: 400px; /* Adjust as needed */
  margin: auto;
  padding: 20px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  //font-weight: bold;
}

.form-input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.field-error {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}

button[type="submit"] {
  background-color: #2b4257;
  color: white;
  padding: 10px 20px;
  border: 2px solid #2b4257;
  font-size: 16px;
  border-radius: 15px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #2b4257;
}

.input-width{
  width: 100%;
  min-width: 300px;
}
// .round
// {
//   background-color: #fff;
//   color:#357ec7;
//   width: 55px;
//   height: 55px;
//   font-size: 15px;
// }