  
.footer {
  left: 0;  
  bottom: 1%;
  width: 100%;
  background-color: #2b4257;
  color: #333;
  text-align: center;
  padding-bottom: 0px;
  font-size: 14px;
  transition: all 0.3s;
  padding-bottom: 0.5%;
}

.footer .copyright {
  text-align: center;
  padding-top: 10px;
  background-color: #2b4257;
  color: white;
  padding-left: 20px;
}

.copyright {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  color: white;
}

.copyright p {
  color: white;
  padding: 0;
  margin: 0;
}