.user-payment-container {
  padding: 20px;
  background-color: #fcf3f3;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.user-payment-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.form-group label {
  flex: 1;
  font-weight: 800;
  color: #555;
  margin-right: 20px; /* Gap between label and value */
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group input[type="date"] {
  flex: 2;
  max-width: 290px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.form-group input[type="checkbox"] {
  margin-right: 10px;
  border: solid rgb(240, 235, 235);
  border-width: 0 3px 3px 0;
}

.submit-btn,
.edit-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #0e3c58;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submit-btn:hover,
.edit-btn:hover {
  background-color: #08344a;
}

.submit-btn:disabled,
.edit-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.submit-btn {
  background-color: #4caf50;
}

.submit-btn:hover {
  background-color: #45a049;
}
