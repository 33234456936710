.notificationCell {
    max-width: 300px;
    white-space: normal;
    word-wrap: break-word;
    text-align: left;
  }
  
  .notificationTable tbody tr:last-child {
    border: none;
  }
  