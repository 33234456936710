$primaryColor: #1d3557;
$secondaryColor: #2b4257;
.two h1 {
  text-transform: capitalize;
}
.rate{
  top: 400px;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #c50000;
}

.two h1 span {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 3em;
  padding-left: 0.25em;
  color: rgba(0, 0, 0, 0.4);
  padding-bottom: 10px;
}
.alt-two h1 {
  text-align:center;
}
.alt-two h1:before {
  left:50%; margin-left:-30px;
}
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(8px);
}

.popup {
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.popup h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #00b7ff;
}

.popup p {
  font-size: 16px;
  margin-bottom: 20px;
}

.popup button {
  padding: 10px 20px;
  background-color: #187e24;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 20px;
}

.popup button:last-child {
  background-color: #dc3545;
}
.clearbizparagraph {
      
  justify-content: center; 
  margin-left: 60px;
  font-weight: bold;
  margin-top: 26px;
}
.ratemycustomerclose{
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 1px;
  right: 18px;
  font-size: 20px;
}
.partnerheading{
  color: #fff;
  font-Weight: bold;
  font-Size: 2.0rem;
  border: "2px solid #1d3557";
  border-Radius: "7px";
  width: "80%";
  background-Color: "#1d3557";
}

.heading {
  color: #fff;
  font-weight: bold;
  font-size: 2.0rem;
  border: 2px solid $primaryColor;
  border-radius: 7px;
  width: 80%;
  background-color: $primaryColor;
}

.heading1 {
  color: #2d4356;
  font-size: 38px;
  font-family: sans-serif;
  font-weight: bold;
  margin: 0px;
}


.customerText {
  color: #fff;
  background-color: #0065AB;
  font-weight: bold;
  padding: 0.7rem;
  border-radius: 6px;
  display: inline-block;
  width: 195px;
  text-align: center;
  margin: 0px;
}


.partnerText {
  color: #fff;
  background-color: $secondaryColor;
  font-weight: bold;
  padding: 0.7rem;
  border-radius: 6px; 
  display: inline-block;
  width: 195px;
  text-align: center;
}

.numberofdisputes {
  color: #fff;
  background-color: #2b4257;
  font-weight: bold;
  border-radius: 6px;
  text-align: center;
}

.searchIcon {
  position: absolute;
  top: 50%;
  left: 11.5rem;
  transform: translateY(-50%);
  color: #000;
}




.SerchandTotalpartners_container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-left: 25px;
  margin-top: 0%;




// Media query for small screens
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-left: 15px;
    
    .customerText {
      width: 120px;
      font-size: 10px;
    }
  }

  // Media query for extra small screens
  @media (max-width: 575px) {
    padding-left: 10px;
  }

}

.your-link-class{
  display: flex;
  justify-content: center;
  align-items: center; 
  flex-direction: row;
  justify-content: space-between;
}