.searchprofilecard{
  margin-top: 70px;
  margin-left: 450px;
  
}
.searchbodycard{
  width: 350px;
  margin-left: 60px;
 
  
}
.centered-avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
text-align: center;
}

.customertype{
margin-left: 110px;
margin-top: -10px;
}
.customerNameinsearchprofile{
  margin-left: 80px;
}
.avatarlogo{
  margin-left: 100px;
}
.searchprofilcontainercard{
  margin-bottom: 180px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
  left: 20px;
  max-width: fit-content;
  max-height: fit-content;
  margin-top: 100px;
}
.searchprofilebutton{
  margin-left: 20px;
  margin-bottom: 10px;
  width: 250px;
}
.card-link{
  margin-top: 50px;
  height: 49px;


}






.popup {
    background-color:'white';
    padding:'20px';
    border-radius:'5px';
    box-shadow: '0 2px 10px rgba(0,0,0,0.1)';
    text-align: 'center';
  }

  .Business_name {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .close {
    color: red;
  }  

  .Rating {
    font-size: 20px;
    font-weight: bold;
  }