.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: absolute;
  top: 100px; /* Adjust this value for the margin from the top */
  left: 50%;
  transform: translate(-50%, 0);
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 85%;
  max-width: 800px;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
}

/* Extra small devices (phones, less than 600px) */
@media (max-width: 600px) {
  .modal-content {
    width: 70%;
    padding: 15px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 768px) {
  .modal-content {
    width: 85%;
    padding: 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .modal-content {
    width: 95%;
    padding: 25px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .modal-content {
    width: 90%;
    padding: 30px;
  }
}

.CloseIcon {
  float: right;
  padding: 5px;
  background-color: transparent;
  border: none;
}

.CloseIcon:hover {
  transform: none;
  background-color: transparent;
  color: red;
  cursor: pointer;
}


.PlanUpgradeImg {
  padding: 0px;
  width: 30%;
  height: 20%;
  object-fit: cover;
}


.Planheading {
  color: rgba(0, 0, 95, 0.781);
  font-weight: bold;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-top: 15px;
  font-size: x-large;
}

.Planpara {
  color: rgba(54, 54, 68, 0.781);
  text-align: center;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.ButtonsContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: center;
  
}