.clearbiz-img{
    width: 150px;
    margin-top: 9px;
}

@media (max-width: 600px) {
  .clearbiz-img {
      width: 100px; 
  }
}

.notification{
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-Bottom: '-25px';

}
.signout{
  margin-top: 37px;
}
