.tableContainer {
  position: relative;
  width: 100%;
  height: 450px;
  overflow-y: auto;
}

.tableContainer::-webkit-scrollbar {
  width: 10px;
}

.tableContainer::-webkit-scrollbar-track {
  background: #cec6c6;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: #2B4257;
  border-radius: 20px;
  border: 1px solid #f1f1f1;
  cursor: pointer;
}

.tableHeader {
  width: 100%;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 0px;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.288);
  border-bottom: 2px solid #1d3557;
  background-color: #f3f3f3;
  font-weight: bold;
  color: #353837;
  border-left: 1px solid #1d3557;
  border-right: 1px solid #1d3557;
}

.table th,
.table td {
  padding: 12px 15px;
  text-align: center;
}

.table th {
  background-color: #2B4257;
  color: #ffffff;
}

/* Alternating row colors */
.table tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

.table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tbody tr td:last-child,
.table thead tr th:last-child {
  text-align: center;
}

.action-icons {
  cursor: pointer;
  font-size: 20px;
  color: #0e3c58;
}

.raisedisputebutton,
.resolveDisputeAction {
  padding: 6px 12px;
  font-size: 14px;
}

.icons-container {
  display: flex;
  justify-content: center;
  gap: 5px;
}

@media only screen and (max-width: 760px), 
       (min-device-width: 768px) and (max-device-width: 1024px) {
  .table, thead, tbody, th, td, tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin: 0 0 1rem 0;
    display: flex;
    flex-direction: column;
  }

  .remove_in_smallScreen {
    display: none;
  }

  td:before {
    position: absolute;
    left: 6px;
    width: 45%;
    padding-right: 10px;
  }

  td:nth-of-type(5):before { content: "MY Ratings"; }
  td:nth-of-type(6):before { content: "Total Ratings"; }
  td:nth-of-type(7):before { content: "Gain/Loss(%)"; }
  td:nth-of-type(8):before { content: "Actions"; }
}
