.image{
    height: 70px;
    border-radius: 50%;
  }
  .icon{
    width: 40px;
    border:1px solid skyblue;
    margin-left: 5px;
  }
  .icon2{
    width: 150px;
    margin-left: auto;
    margin-right: 0;
  }
  .logo-card{
      background-color: #ffffff;
      border-radius: 8px;
      padding: 4px;
  }
  
  .company{
      width: 50px;
      height: 50px;   
      border-radius: 50%;
      border:white ,2px,solid;
  }
  
  .name{
   color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    
  }
  
  @media (max-width: 768px) {  
    .bgContainer{
      background-color: #2b4257;
      color: #ffffff;
    }
  
    .name{
      color: #ffffff;
      font-size: 15px !important; 
      font-weight: 500;
      line-height: 24px;
      
    }
  
  }
  @media (max-width: 740px){
    .icon{
      width: 40px;
      height: 40px;
      margin-top: 15px;
      margin-left: 25px;
      }
      .icon2{
        width: 100px;
        margin-left: auto;
        margin-right: 0px;
      }
  }