.App {
    font-family: sans-serif;
 
    
  }


  .container {
   height: auto;
    
  }
  .commentArea {
    
    
    padding: 10px;
    border-radius: 5px;
    height: auto;
    
}

h6 {
    font-weight: bolder;
}



.comment { 
    padding: 5px;
}

.cotainer {
    
    border: 1px solid rgb(178, 178, 178);
    border-radius: 0px 0px 10px 10px;
    width: auto;
    height: auto;
    background-color: white;
    padding: 5px;
}

.comment-text {
background: #2b4257;
    margin-bottom: 0px;
    height: auto;
    color: white;
    text-align: center;
    border-radius: 10px 10px 0px 0px;
    line-height: 1.8;
}

