.model_resolve_dispute{
  width:100%
  }
  .textFieldWithMargin {
    margin-bottom: 20px;
  }
  .numberofdisputes{
    color: #fff;
    background-Color: #0065AB;
    font-Weight: bold;
    padding: 0.7rem;
    border-Radius: 6px;
  align-items: center;
margin-left: 31px;
  }