.noresultsearchcontainer{
    margin-left: 100px;
}.card {
    width: 100%;
    max-width: 500px; 
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border-radius: 10px;
  }
  
  h2 {
    font-size: 1.5rem;
    color: #333;
    margin: 10px 0;
  }
  
  p {
    font-size: 1rem;
    color: #666;
    margin: 5px 0;
  }
  .card_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
  } 
  .business_partner_para{
 font-weight: bold;

  }
.no_search_result{
    font-weight: bold;
    background-color: #2b4257;
    color: white;
    margin-top: 125px;
}
.addcustomer {
  margin-top: 23px; /* Adjust this value as needed */
}
