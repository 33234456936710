@import url('https://fonts.googleapis.com/css2?family=Mulish&family=Open+Sans&display=swap');
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");


* {
  margin: 0;
  padding: 0;
  box-sizing:initial;
  font-family: "Poppins", sans-serif;
}

.navbar {
  width: auto;
  background-color: #ffffff !important;
}

.navbar a {
  font-size: 1rem;
  text-transform: capitalize;
}
