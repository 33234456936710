.header{
    background-color: #8d93da;
    border: 1px solid rgb(232, 230, 230);
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    font-weight: 300;
    color: white;
    font-size: large;
    height: auto;
    width:100%;
}
.main {
    border: 1px solid rgb(232, 230, 230);
    border-radius: 0px 0px 10px 10px ;
    padding: 10px;
    text-align: center;
}
.fontst{
    font-size: 25;
    font-weight:bold;
}