.raisedbuttoncontainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 65px;
  margin-bottom: -10px;
}

.dashboard-card {
  height: 60px;
  border-radius: 8px;
  margin: 16px;
  padding: 16px;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  background: #98A9D7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.raisebuttonclose {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 13px;
  font-size: 20px;
}

.abc-card {
  height: 60px;
  border-radius: 8px;
  margin: 26px;
  padding: 16px;
  color: #fff;
  background: #2b4257;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.xyz-card {
  height: 60px;
  border-radius: 8px;
  margin: 16px;
  padding: 16px;
  color: #000;
  background: #b9b9b9;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.margin-top {
  margin-top: 500px;
}

.myrating {
  margin-top: -50px;
}

.rating {
  position: absolute;
  width: 290px;
  height: 400px;
  left: 232px;
  top: 0px;
  margin-left: 120px;
  padding-top: 100px;
  padding-left: 15px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid;
  margin-top: -50px;
}

.rat {
  padding-left: 40px;
}

.total {
  padding-left: 90px;
}

/* Updated for Gauge Integration */
.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust to fit gauge */
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.avater-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

/* Responsive Updates */
@media (max-width: 768px) {
  .dashboard-card,
  .abc-card,
  .xyz-card {
    width: 100%;
  }
  .container {
    height: 80px;
    width: 80px;
  }
}

.Boxstyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  background-color: #fff;
  box-shadow: 0px 0px 24px rgba(77, 69, 69, 0.5);
  padding: 16px;
  border-radius: 10px;
}

.modal-buttons-container {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

.raisedisputebutton {
  border-radius: 5px;
  width: 110px;
  padding: 8px 16px;
}

.Myratingsheading {
  font-weight: bold;
  color: #2b4257;
  padding:10px 0px;
}

.Raised_on {
  margin-bottom: 35px;
}

.partnerdetailrating {
  background-color: #fff;
  height: 100%;
  padding: 8px;
}

/* Adjustments for Partner Detail Rating */
@media screen and (max-width: 900px) {
  .partnerdetailrating {
    height: calc(100% + 10px);
  }
}

/* Additional Styling for Consistency */
.star-container {
  display: flex;
  margin-left: -50px;
}

.tub {
  margin-left: 250px;
  margin-top: 44px;
}

// .riv{
//   background-color: #0065AB;
//   color: #fff;
//   border-radius: 10px;
//   padding: 10px;
//   margin-bottom: "20px";
// }

