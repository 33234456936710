 
  
  @media (min-width: 1200px) {
  
    #main,
    #footer {
      margin-left: 300px;
    }
  }
  
  @media (max-width: 1199px) {
    .toggle-sidebar .sidebar {
      left: 0;
    }
  }
  
  @media (min-width: 1200px) {
  
    .toggle-sidebar #main,
    .toggle-sidebar #footer {
      margin-left: 0;
    }
  
    .toggle-sidebar .sidebar {
      left: -300px;
    }
  }
  
  .sidebar-nav {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .sidebar-nav li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .sidebar-nav .nav-item {
    margin-bottom: 5px;
  }
  
  .sidebar-nav .nav-heading {
    font-size: 11px;
    text-transform: uppercase;
    color: #899bbd;
    font-weight: 600;
    margin: 10px 0 5px 15px;
  }
  
  .sidebar-nav .nav-link {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    color: #4154f1;
    transition: 0.3;
    background: #f6f9ff;
    padding: 10px 15px;
    border-radius: 4px;
  }
  
  .sidebar-nav .nav-link i {
    font-size: 16px;
    margin-right: 10px;
    color: #4154f1;
  }
  
  .sidebar-nav .nav-link.collapsed {
    color: #012970;
    background: #fff;
  }
  
  .sidebar-nav .nav-link.collapsed i {
    color: #899bbd;
  }
  
  .sidebar-nav .nav-link:hover {
    color: #4154f1;
    background: #f6f9ff;
  }
  
  .sidebar-nav .nav-link:hover i {
    color: #4154f1;
  }
  
  .sidebar-nav .nav-link .bi-chevron-down {
    margin-right: 0;
    transition: transform 0.2s ease-in-out;
  }
  
  .sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
    transform: rotate(180deg);
  }
  
  .sidebar-nav .nav-content {
    padding: 5px 0 0 0;
    margin: 0;
    list-style: none;
  }
  .active {
    background: #f6f9ff;
    color: #000000;
  }
  .no-active-sidebar {
    background-color: #fff;
    color: #000000 !important;
  }
  
  .sidebar-nav .nav-content a {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #012970;
    padding: 10px 0 10px 40px;
    transition: 0.3s;
  }
  
  .sidebar-nav .nav-content a i {
    font-size: 6px;
    margin-right: 8px;
    line-height: 0;
    border-radius: 50%;
  }
  
  .sidebar-nav .nav-content a:hover,
  .sidebar-nav .nav-content a.active {
    color: #4154f1;
  }
  
  .sidebar-nav .nav-content a.active i {
    background-color: #4154f1;
  }
  
  #sidebar.active {
    margin-left: -17rem;
  }
  
  #content.active {
    width: 100%;
    margin: 0;
  }
  
  @media (max-width: 768px) {
    #sidebar {
      margin-left: -17rem;
    }
    #sidebar.active {
      margin-left: 0;
    }
    #content {
      width: 100%;
      margin: 0;
    }
    #content.active {
      margin-left: 17rem;
      width: calc(100% - 17rem);
    }
  }
   
  
  .separator {
    margin: 3rem 0;
    border-bottom: 1px dashed #fff;
  }
  
  .text-uppercase {
    letter-spacing: 0.1em;
  }
  
  .text-gray {
    color: #aaa;
  }

  .dropdown-menu {
    border-radius: 4px;
    padding: 10px 0;
    animation-name: dropdown-animate;
    animation-duration: 0.2s;
    animation-fill-mode: both;
    border: 0;
    box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
  }
  
  .dropdown-menu .dropdown-header,
  .dropdown-menu .dropdown-footer {
    text-align: center;
    font-size: 15px;
    padding: 10px 25px;
  }
  
  .dropdown-menu .dropdown-footer a {
    color: #444444;
    text-decoration: underline;
  }
  
  .dropdown-menu .dropdown-footer a:hover {
    text-decoration: none;
  }
  
  .dropdown-menu .dropdown-divider {
    color: #a5c5fe;
    margin: 0;
  }
  
  .dropdown-menu .dropdown-item {
    font-size: 14px;
    padding: 10px 15px;
    transition: 0.3s;
  }
  
  .dropdown-menu .dropdown-item i {
    margin-right: 10px;
    font-size: 18px;
    line-height: 0;
  }
  
  .dropdown-menu .dropdown-item:hover {
    background-color: #f6f9ff;
  }
  
  @media (min-width: 768px) {
    .dropdown-menu-arrow::before {
      content: "";
      width: 13px;
      height: 13px;
      background: #fff;
      position: absolute;
      top: -7px;
      right: 20px;
      transform: rotate(45deg);
      border-top: 1px solid #eaedf1;
      border-left: 1px solid #eaedf1;
    }
  }
  
  @keyframes dropdown-animate {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  
    0% {
      opacity: 0;
    }
  }

  .logo {
    line-height: 1;

  }
  
  @media (min-width: 1200px) {
    .logo {
      width: 280px;
      
    }
  }
  
  .logo img {
    max-height: 26px;
    margin-right: 6px;
  }
  
  .logo span {
    font-size: 26px;
    font-weight: 700;
    color: #012970;
    font-family: "Nunito", sans-serif;
  }
  
  .header {
    transition: all 0.5s;
    z-index: 997;
    height: 60px;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
    padding-left: 20px;
    /* Toggle Sidebar Button */
    /* Search Bar */
  }
  
  .header .toggle-sidebar-btn {
    font-size: 32px;
    padding-left: 10px;
    cursor: pointer;
    color: #012970;
  }
  .bellicon{
    margin-left: 300px;
  }
  .navbarimage{
    margin-right: 10px;
  }
  .searchbutton{
  height: 40px;
  margin-left: 1px;
  width: 50px;
  background-color: #012970;
  color: #eaedf1;
  margin-top: 0px;
  border-radius: 20px;
  }
  button {
    transition: all 0.2s ease-in-out;
  }
  
  button:hover {
    transform: scale(1);
    background-color: #2e9acc;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  button .fa-search {
    animation: spin 1s linear infinite;
  }

  .searchfield {
    width: 300px;
    height: 40px;
    padding: 10px;
    font-size: 16px;
    border-radius: 20px;
    border: 2px solid #ccc;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease-in-out;
  }
  
  .searchfield:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 1px 1px 5px rgba(52, 152, 219, 0.5);
  }
  
  .header .search-bar {
    min-width: 360px;
    padding: 0 20px;
    display: flex;
    font-size: 16px;
    
  }

  
  @media (max-width: 1199px) {
    .header .search-bar {
      position: fixed;
      top: 50px;
      left: 0;
      right: 0;
      padding: 20px;
      box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
      background: white;
      z-index: 9999;
      transition: 0.3s;
      visibility: hidden;
      opacity: 0;
    }
  
    .header .search-bar-show {
      top: 60px;
      visibility: visible;
      opacity: 1;
    }
  }
  
  .header .search-form {
    width: 100%;
  }
  
  .header .search-form input {
    font-size: 14px;
    color: #012970;
    border: 1px solid rgba(1, 41, 112, 0.2);
    padding: 7px 38px 7px 8px;
    border-radius: 3px;
    transition: 0.3s;
    width: 100%;
  }
  
  .header .search-form input:focus,
  .header .search-form input:hover {
    outline: none;
    box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
    border: 1px solid rgba(1, 41, 112, 0.3);
  }
  
  .header .search-form button {
    border: 0;
    padding: 0;
    margin-left: -30px;
    background: none;
  }
  
  .header .search-form button i {
    color: #012970;
  }
  
  /*--------------------------------------------------------------
  # Header Nav
  --------------------------------------------------------------*/
  .header-nav ul {
    list-style: none;
  }
  
  .header-nav>ul {
    margin: 0;
    padding: 0;
  }
  
  .header-nav .nav-icon {
    font-size: 22px;
    color: #012970;
    margin-right: 25px;
    position: relative;
  }
  
  .header-nav .nav-profile {
    color: #012970;
  }
  
  .header-nav .nav-profile img {
    max-height: 36px;
  }
  
  .header-nav .nav-profile span {
    font-size: 14px;
    font-weight: 600;
  }
  
  .header-nav .badge-number {
    position: absolute;
    inset: -2px -5px auto auto;
    font-weight: normal;
    font-size: 12px;
    padding: 3px 6px;
  }
  
  .header-nav .notifications {
    inset: 8px -15px auto auto !important;
  }
  
  .header-nav .notifications .notification-item {
    display: flex;
    align-items: center;
    padding: 15px 10px;
    transition: 0.3s;
  }
  
  .header-nav .notifications .notification-item i {
    margin: 0 20px 0 10px;
    font-size: 24px;
  }
  
  .header-nav .notifications .notification-item h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .header-nav .notifications .notification-item p {
    font-size: 13px;
    margin-bottom: 3px;
    color: #919191;
  }
  
  .header-nav .notifications .notification-item:hover {
    background-color: #f6f9ff;
  }
  
  .header-nav .messages {
    inset: 8px -15px auto auto !important;
  }
  
  .header-nav .messages .message-item {
    padding: 15px 10px;
    transition: 0.3s;
  }
  
  .header-nav .messages .message-item a {
    display: flex;
  }
  
  .header-nav .messages .message-item img {
    margin: 0 20px 0 10px;
    max-height: 40px;
  }
  
  .header-nav .messages .message-item h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #444444;
  }
  
  .header-nav .messages .message-item p {
    font-size: 13px;
    margin-bottom: 3px;
    color: #919191;
  }
  
  .header-nav .messages .message-item:hover {
    background-color: #f6f9ff;
  }
  
  .header-nav .profile {
    min-width: 240px;
    padding-bottom: 0;
    top: 8px !important;
  }
  
  .header-nav .profile .dropdown-header h6 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    color: #444444;
  }
  
  .header-nav .profile .dropdown-header span {
    font-size: 14px;
  }
  
  .header-nav .profile .dropdown-item {
    font-size: 14px;
    padding: 10px 15px;
    transition: 0.3s;
  }
  
  .header-nav .profile .dropdown-item i {
    margin-right: 10px;
    font-size: 18px;
    line-height: 0;
  }
  
  .header-nav .profile .dropdown-item:hover {
    background-color: #f6f9ff;
  }
  
  .sidebar {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index: 996;
    transition: all 0.3s;
    padding: 20px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #aab7cf transparent;
    box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
  }
  
  @media (max-width: 1199px) {
    .sidebar {
      left: -300px;
    }
  }
  
  .sidebar::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #fff;
  }
  
  .sidebar::-webkit-scrollbar-thumb {
    background-color: #aab7cf;
  }
  
  @media (min-width: 1200px) {
  
    #main,
    #footer {
      margin-left: 300px;
    }
  }
  
  @media (max-width: 1199px) {
    .toggle-sidebar .sidebar {
      left: 0;
    }
  }
  
  @media (min-width: 1200px) {
  
    .toggle-sidebar #main,
    .toggle-sidebar #footer {
      margin-left: 0;
    }
  
    .toggle-sidebar .sidebar {
      left: -300px;
    }
  }
  
  
  
  .nav-tabs-bordered {
    border-bottom: 2px solid #ebeef4;
  }
  
  .nav-tabs-bordered .nav-link {
    margin-bottom: -2px;
    border: none;
    color: #2c384e;
  }
  
  .nav-tabs-bordered .nav-link:hover,
  .nav-tabs-bordered .nav-link:focus {
    color: #4154f1;
  }
  
  .nav-tabs-bordered .nav-link.active {
    background-color: #fff;
    color: #4154f1;
    border-bottom: 2px solid #4154f1;
  }
  