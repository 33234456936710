.SideBarActive{
    color: #213554;
    font-weight: 600;
    font-size: 21px;  /* font-size*/
  }
  
  .inactive{
    color: #213554ab;
    font-weight: 400;
    font-size: 20px; /* font-size*/
  
  }
  
  @media (max-width: 767px) {
    .SideBarActive, .inactive{
      color: #2b4257;
      font-weight: 500;
    }
  }


  .SubscriptionCardContainer {
    display: flex;
    width: '90%';
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }


  .ListItem {
    text-decoration: none;
    color: rgb(117, 119, 153);

  
    &:hover {
    text-decoration: none;
    cursor: pointer; /* Example cursor style on hover */
  }

   &:focus {
    text-decoration: none;
     color: rgb(31, 35, 90);
    
   }

  }


