.termsHeading{
    color: #213554;
    font-size: 24px;
    font-weight: 600;
  }
   
  .text-color{
    color: #515978;
    font-size: 15px;
  }
  
  
  
  .separate{
    background-color: #213554;
    width: 195px;
    height: 5px;
    margin-top: 16px;
    font-weight: 700;
  }
  
  .separate1{
    background-color: #213554;
    width: 45%;
    height: 5px;
    margin-top: 16px;
    font-weight: 700;
  }
  
  .separate2{
    background-color: #213554;
    width: 105px;
    height: 5px;
    margin-top: 16px;
    font-weight: 700;
  }
  
  .separate3{
    background-color: #213554;
    width: 125px;
    height: 5px;
    margin-top: 16px;
    font-weight: 700;
  }
  
  
  .separate4{
    background-color: #213554;
    width: 155px;
    height: 5px;
    margin-top: 16px;
    font-weight: 700;
  }
  .bold{
    font-weight: 700;
  }
  
  .listPadding{
    padding-left: 30px;
  }