@import url('https://fonts.googleapis.com/css2?family=Georgia:wght@400;500&display=swap');

.features {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    width: 100%;
  }
  
  .feature-item {
    display: flex;
    align-items: center;
    color: #31303b; 
    font-size: 1.25rem;
    font-weight: 500;
    font-family: 'Georgia', serif;

    gap: 10px; 
  }
  
  .feature-icon {
    font-size: 2rem; 
  }
  
  .our-network {
    text-align: center;
    padding: 20px;
  }
  
  .network-title {
    font-family: 'Georgia', serif; /* Customize font as needed */
    font-size: 2rem;
    color: #000;
    margin-bottom: 20px;
  }
  
  .network-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px; /* Space between each logo */
  }
  
  .network-item img {
    max-width: 150px; /* Adjust logo size as needed */
    height: auto;
  }
  .network-logos img {
    max-height: 80px;
  }

  /* Benefits*/
  .banner {
    background-color: #ffffff;
    color: #000;
    padding: 2%;
    border-radius: 8px;
    margin: 2%;
    width:80%;
    position: relative;
    z-index: 1000;
    bottom:140px;
  }
  
  .banner h2 {
    font-weight: bold;
    color:#2b4257;
    margin-bottom: 2%;
  }
  .banner h5 {
   
    color:#2b4257;
  }
  .banner h6{
    color: #2b4257;
    text-align: center;
    
  }
  
  .get-started-button {
    padding: 10px 20px;
    background-color: #2b4257;
    color: white;
    border: none;
    border-radius: 25px;
    width: 25%;
    cursor: pointer;
  }
  
.get-started-button:hover {
  background-color: #385570;
  border: none;
}
  
 /* WhyChooseUs.css */
 .why-choose-us-container {
  display: flex;
  align-items: center;
  padding-top: 8%;
  width: 100%;
  padding-bottom: 8%;
  font-family: Arial, sans-serif;
  color: #333;
  overflow: hidden; 
  margin-bottom: 10%;
}

.why-choose-us-left {
  background-color: #2b4257;
  color: #ffffff;
  padding: 8% 0%;
  padding-right: 7%;
  min-width: 550px;
  border-radius: 0 160px 160px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  transform: translateX(-75%);
  transition: all 2.0s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}
.why-choose-us-left.expanded,
 .why-choose-us-left.hover {
  transform: translateX(0);
}


.why-choose-us-left h2 {
  font-size: 1.8em;
  margin: 0;
}

.why-choose-us-right {
  flex: 2;
  padding-left: 15%;
}

.point {
  font-size: 1.2em;
  margin: 15px 0;
  display: flex;
  align-items: center;
}

.point span {
  font-weight: bold;
  margin-right: 15px;
  font-size: 1.2em;
}

.divider {
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
  width: 90%;
}
@media screen and (max-width: 1024px) {
  .why-choose-us-container {
    /* padding: 3rem 4rem; */
    gap: 3rem;
  }
  .why-choose-us-left {
    transform: translateX(-85%);
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  .why-choose-us-left.expanded,
 .why-choose-us-left.hover {
  transform: translateX(0);
}

  .why-choose-us-left h2 {
    font-size: 2rem;
  }
  .features{
    flex-direction: column;
    gap: 20px;
    padding: 2%;
    padding-top: 10%;
    align-items: flex-start;
    background: linear-gradient(177.95deg, #FFFFFF 9.32%, rgba(185, 195, 252, 0.41) 77.36%);

  }
  .feature-item{
    font-size: 1rem;
    align-items: flex-start;
  }
}

@media screen and (max-width: 768px) {
  .why-choose-us-container {
    flex-direction: column;
    /* padding: 2rem; */
    gap: 2rem;
  }
  .why-choose-us-left {
    transform: translateX(-85%);
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  .why-choose-us-left.expanded,
  .why-choose-us-left.hover {
   transform: translateX(0);
 }
 
  .why-choose-us-left, 
  .why-choose-us-right {
    flex: 1;
    width: 100%;
  }

  .point {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .network-item img {
    max-width: 100px; 
    height: auto;
  }
  .network-logos img {
    max-height: 80px;
  }
  /* .why-choose-us-left {
    transform: translateX(-85%);
    transition: transform 0.3s ease;
    cursor: pointer;
  }*/
 
   .why-choose-us-left{
    transform:none;
    transition: none;
    cursor:none;
    min-width: 150px;
   }
   .why-choose-us-left.expanded,
   .why-choose-us-left.hover {
   transform: none;
 }
   .why-choose-us-left h2 {
    font-size: 1.8rem;
  } 
  .point {
    font-size: 0.9rem;
  }

  .point span {
    width: 25px;
    height: 25px;
  }
}
@media (max-width: 600px) {
  .features{
    flex-direction: column;
    gap: 20px;
    padding: 2%;
    padding-top: 10%;
    align-items: flex-start;
    background: linear-gradient(177.95deg, #FFFFFF 9.32%, rgba(185, 195, 252, 0.41) 77.36%);

  }
  .feature-item{
    font-size: 1rem;
    align-items: flex-start;
  }
  .network-item img {
    max-width: 100px; 
    height: auto;
  }
  .network-logos img {
    max-height: 80px;
  }
}
@media (max-width: 600px) {
  .banner {
    bottom:90px;
  }
  .banner h2,
  .banner h5,
  .banner h6 {
    font-size: 1rem;
  }

  .get-started-button {
    width: 100%;
  }
}

